<style lang="scss" scoped>
.comp-picker-link {
  .btn-item {
    display: flex;
    align-items: center;

    .btn-linkpath {
      flex: 1;
    }

    .input-m {
      margin-left: 3px;
    }
  }

  .linktype-radio {
    margin-top: 10px;
    margin-bottom: 6px;

    .el-radio {
      margin-right: 8px;

      &:last-child {
        margin-right: 0;
      }
    }

    .el-radio__label {
      padding-left: 2px;
      font-size: 13px;
    }
  }

  .btn-linkpath {
    padding: 0 8px;
    color: var(--themeColor);
    border: 1px solid var(--themeColor);
    background-color: #fff;
    font-size: 12px;
    height: 32px;
    line-height: 32px;
    border-radius: 3px;
    max-width: 200px;
    @include text-overflow();
  }

  input::-webkit-input-placeholder {
    font-size: 12px;
  }

  .el-input__inner {
    padding: 0 8px;
  }

  .h5-link {
    margin-top: 10px;
    display: block;
  }

  .video-list {
    display: flex;
  }

  .register-channel {
    padding-top: 15px;

    .register-channel-position {
      padding-top: 15px;
      display: flex;
      align-items: center;
    }

    // &-label {
    //   padding-right: 10px;
    //   font-size: 12px;
    // }

    .el-button {
      margin-left: 10px;
    }
  }
}
</style>
<style lang="scss">
.register-channel-option {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.register-channel-label {
  padding-right: 10px;
  font-size: 12px;
}

.register-channel-item {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
}

.register-channel-popover-button {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.register-channel-popover {
  display: flex;
  align-items: center;

  .el-input {
    width: 200px;
  }

  .el-button {
    margin-left: 10px;
  }
}
</style>
<template>
  <div class="comp-picker-link">
    <div class="btn-item">
      <div class="btn-linkpath" @click="onPickerPath">
        {{ getLabelName() }}
      </div>
      <el-select class="input-m" v-model="localValue.rule_id" placeholder="请选择" filterable remote clearable
        v-if="localValue && localValue.id == 'drawer-shop'" size="small">
        <el-option v-for="item in list" :key="item.rule_id" :label="item.rule_title" :value="item.rule_id">
        </el-option>
      </el-select>
      <el-input v-model="localValue.liveId" placeholder="请填写抖音直播ID" v-if="localValue && localValue.id == 'douyinLive'"
        size="small" class="input-m" />
      <el-input v-model="localValue.imId" placeholder="请填写抖音客服ID" v-if="localValue && localValue.id == 'douyinIm'"
        size="small" class="input-m" />
      <el-input v-model="localValue.byte_mini_shop_id" placeholder="请填写抖音小店ID"
        v-if="localValue && localValue.id == 'douyinStore'" size="small" class="input-m" />
    </div>
    <div v-if="localValue.id === 'CopyLink'">
      <el-input v-model="localValue.CopyUrl" placeholder="请输入链接" class="h5-link" size="small" type="text" />
      <el-input v-model="localValue.CopyTitle" placeholder="请输入复制文案" class="h5-link" size="small" type="text" />
    </div>
    <div v-if="localValue.id === 'thirdpage'">
      <el-input v-model="localValue.linkTitle" placeholder="请输入跳转路径" class="h5-link" size="small" type="text" />
      <el-input v-model="localValue.linkAppid" placeholder="请输入对应小程序APPID" class="h5-link" size="small" type="text" />
      <div v-if="isShowLinkprogram">
        <span>是否半屏跳转:</span>
        <el-radio-group v-model="localValue.linkProgram" style="display: inline-block;padding-left: 10px;">
          <el-radio label="1">否</el-radio>
          <el-radio label="2">是</el-radio>
        </el-radio-group>
      </div>
    </div>
    <div v-if="localValue.id === 'videopage' && isShowVideo">
      <el-radio-group v-model="localValue.autoplay" class="linktype-radio">
        <el-radio :label="true"> 开启自动播放 </el-radio>
        <el-radio :label="false"> 关闭自动播放 </el-radio>
      </el-radio-group>
      <div class="video-list">
        <SpImagePicker v-model="localValue.video_cover" size="small" class="video-link" text="封面" />
        <SpVideoPicker type="object" v-model='localValue.video' size='small' class="video-link" />
      </div>
    </div>
    <div class="register-channel" v-if="localValue.id === 'register' || localValue.linkPage === 'ordertogift'">
      <div class="register-channel-item">
        <span class="register-channel-label">注册渠道</span>
        <el-select v-model="localValue.channel" placeholder="请选择" class="register-channel-select" size="small" clearable
          filterable remote reserve-keyword :remote-method="getChannelList">
          <el-option v-for="item in options" :key="item.value" :value="item.value" :label='item.label'
            class="register-channel-option">
            <!-- <span>{{ item.label }}</span> -->
            <!-- <i class="el-icon-delete" @click="delChannel(item.value)"></i> -->
          </el-option>
        </el-select>
        <el-popover placement="bottom" width="320" trigger="click" ref="popover">
          <div class="register-channel-popover-content">
            <div class="register-channel-popover">
              <span class="register-channel-label">注册渠道名称</span>
              <el-input v-model="channelName" placeholder="请输入渠道名称" size="small" />
            </div>
            <div class="register-channel-popover-button">
              <el-button size="small" @click="cancelChannel">取消</el-button>
              <el-button type="primary" size="small" @click="addChannel">确定</el-button>
            </div>
          </div>
          <el-button type="text" size="small" slot="reference">新增渠道</el-button>
        </el-popover>
      </div>
      <div class="register-channel-item">
        <span class="register-channel-label">协议底部位置</span>
        <el-input-number controls-position="right" v-model="localValue.bottom" class="register-channel-select"
          size="small"></el-input-number>
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash'
import { mapGetters } from "vuex";
import { isObject } from '@/utils'
import { getShowRuleList } from '@/api/template'
import { LINK_PATH } from '@/consts'
import api from '@/api'
export default {
  name: 'CompHotPicker',
  props: {
    value: {
      type: [Object, Array],
      default: () => { }
    },
    isShowH5Link: {
      // 不展示自定义链接label true：展示
      type: Boolean,
      default: true
    },
    isShowLinkprogram: {
      type: Boolean,
      default: false
    },
    isShowVideo: {
      type: Boolean,
      default: true
    },
    wgtType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      localValue: {
        linkPage: '',
        id: '',
        title: '',
        ctoken: '',
        linkType: 0,
        linkUrl: '',
        video_cover: '',
        CopyUrl: "",
        CopyTitle: "",
        autoplay: false,
        bottom: 0
      },
      list: [],
      options: [],
      channelName: ''
    }
  },
  watch: {
    // value: {
    //   immediate: true,
    //   handler(nVal) {
    //
    //     this.localValue = {
    //       ...this.localValue,
    //       ...nVal
    //     }
    //   }
    // }
    localValue: {
      deep: true,
      handler: function (nVal, oVal) {
        this.$emit('input', nVal)
        this.$emit('change', nVal)
        if (nVal.id == 'register' || nVal.linkPage === 'ordertogift') {
          this.getChannelList()
        }
      }
    },
  },

  computed: {
    ...mapGetters([
      'template_name',
      'wxapp_id'
    ])
  },
  created() {
    const localValue = Object.assign(this.localValue, this.value)
    const { video } = localValue
    localValue.video = isObject(video) ? video : {}
    this.localValue = cloneDeep(localValue)
    if (localValue.id == 'drawer-shop') {
      this.getList()
    }
  },
  methods: {
    async onPickerPath() {
      const { template_douyin = 0 } = this.$route.query
      const { linkPage, id } = this.localValue
      const res = await this.$picker.path({
        data: id,
        tab: linkPage,
        multiple: false,
        wxapp_id: this.wxapp_id,
        template_name: this.template_name,
        isDouyin: template_douyin == 1,
        wgtType: this.wgtType,
        isHotWords: false
      })

      this.localValue = {
        ...res,
        linkType: 0,
        linkUrl: '',
        video_cover: '',
        CopyUrl: "",
        CopyTitle: "",
        autoplay: false,
        video: {}
      }
      if (res.id == "drawer-shop") {
        this.getList()
      } else {
        this.$set(this.localValue, 'rule_id', '')
      }
    },
    getLabelName() {
      const { linkPage, title, id } = this.localValue
      if (linkPage) {
        return `${LINK_PATH[linkPage]}：${id ? '[' + id + ']' : ''} ${title}`
      } else {
        return '选择路径'
      }
    },
    async getList() {
      const { template_douyin = 0 } = this.$route.query
      const res = await getShowRuleList({
        page: 1,
        page_size: -1,
        platform: template_douyin == 1 ? 'byte_mini' : 'wxapp',
      })
      this.list = [{ rule_id: '0', rule_title: '全部门店' }, ...res.data.data.list]
    },
    async getChannelList(name = '') {
      const { data } = await api.member.getMemberChannelList({ channel_name: name, channel_id: '' })
      let list = data?.data?.list || []
      this.options = list.map(item => ({ value: item.channel_id, label: item.channel_name }))
    },
    async addChannel() {
      if (!this.channelName) {
        this.$message.warning('请输入渠道名称')
        return
      }
      await api.member.createMemberChannel({ channel_name: this.channelName })
      this.$message.success('添加成功')
      this.channelName = ''
      this.$refs.popover.doClose();
      this.getChannelList()
    },
    delChannel(value) {
      this.options = this.options.filter(item => item.value != value)
    },
    cancelChannel() {
      this.channelName = ''
      this.$refs.popover.doClose();
    }
  },
}
</script>
