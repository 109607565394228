import { pickBy } from "@/utils";
import CompHotSetting from "../../comps/comp_hotSetting";
import AttrHotSetting from "./attr-hotsetting";
import AttrChannel from "./attr-channel.vue";

export default {
  name: "imgHotzone",
  setting: [
    // { label: "标题", key: "title", component: "input", value: "热区图" },
    // {
    //   label: "副标题",
    //   key: "subtitle",
    //   component: "input",
    //   value: "图上随意画块块",
    // },
    { label: "楼层", key: "floor_var", component: "input" },
    { label: "组件间距", key: "padded", component: "switch", value: true },
    {
      label: "挂件可见用户",
      key: "visible",
      component: "checkbox",
      options: [
        { name: "非会员", label: "one" },
        { name: "会员", label: "two" },
      ],
      value: ["one"],
    }, {
      label: "可见用户渠道",
      key: "channel",
      component: function (h, { key }) {
        return (
          <AttrChannel v-model={this.value[key]} uuid={this.value.uuid} />
        );
      },
      value: [],
      isShow: function () {
        return this.value.visible.some((v) => v === "two");
      },
    },
    {
      label: "热区设置",
      key: "data",
      component: function (h, { key }) {
        return (
          <CompHotSetting v-model={this.value[key]} uuid={this.value.uuid} />
        );
      },
      value: { imgUrl: "", data: [] },
    },
  ],
  transformIn: (v) => {
    const {
      name,
      base,
      config: { imgUrl,...config },
      data,
    } = v;
    return {
      name,
      ...base,
      ...config,
      data: {
        imgUrl,
        data,
      },
    };
  },
  transformOut: (v) => {
    return pickBy(v, {
      name: "name",
      base: (v) => {
        return pickBy(v, {
          // title: "title",
          // subtitle: "subtitle",
          padded: "padded",
          floor_var: "floor_var",
          // style: "style",
        });
      },
      config: (v) => {
        return pickBy(v, {
          imgUrl: "data.imgUrl",
          visible: "visible",
          channel: "channel",
        });
      },
      data: "data.data",
    });
  },
};
