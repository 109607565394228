import { pickBy } from "@/utils";
import StyleSetting from "./comps/style-setting.vue";
import ActiveSetting from "./comps/active-setting.vue";
export default {
  name: "aroundStoreOne",
  setting: [
    {
      label: "组件用途",
      key: "purpose",
      component: "select",
      options: [
        { name: "购买引导", label: "" },
        { name: "以旧换新", label: "trade_in" },
        { name: "线下活动", label: "offline_activity" },
        { name: "售后维修", label: "support_repair" },
  //       component_type 使用方式
	// trade_in：以旧换新
	// offline_activity：指定线下活动
	// support_repair：售后维修
      ],
      value: "",
    },
    {
      label: "背景图",
      key: "bgImg",
      component: function (h, { key }) {
        return <SpImagePicker v-model={this.value[key]} size="small" />;
      },
      value: "",
    }, {
      label: "宽度",
      key: "width",
      component: "number",
      position: "right",
      append: "%",
      value: 100,
      max: 100,
      min: 0,
    }, {
      label: "底部距离",
      key: "bottom",
      component: "number",
      position: "right",
      append: "px",
      min: 0,
      value: 0,
    }, {
      label: "门店样式",
      key: "storeStyle",
      component: function (h, { key }) {
        return (
          <StyleSetting
            v-model={this.value[key]}
            uuid={this.value.uuid}
          />
        );
      },
      value: {
        color: "#000",
        fontSize: "12",
      }
    }, {
      label: "数据样式",
      key: "deliveryStyle",
      component: function (h, { key }) {
        return (
          <StyleSetting
            v-model={this.value[key]}
            uuid={this.value.uuid}
          />
        );
      },
      value: {
        color: "#000",
        fontSize: "12",
      }
    }, {
      label: "文案样式",
      key: "textStyle",
      component: function (h, { key }) {
        return (
          <StyleSetting
            v-model={this.value[key]}
            uuid={this.value.uuid}
          />
        );
      },
      value: {
        color: "#000",
        fontSize: "12",
      }
    }, {
      label: "配送信息",
      key: "deliveryInfo",
      component: "radio",
      options: [
        { name: "门店距离", label: "1" },
        { name: "门店距离+配送时效", label: "2" },
      ],
      value: "1"
    }, {
      label: "关联活动",
      key: "linkActivity",
      component: function (h, { key }) {
        return (
          <ActiveSetting
            v-model={this.value[key]}
          />
        );
      },
      value: {
        activity_id: '',
        activity_title: ''
      },
      isShow: function () {
        return this.value.purpose === 'offline_activity';
      }
    }
  ],
  transformIn: (v) => {
    const { name, base, config } = v;
    return {
      name,
      ...base,
      ...config
    };
  },
  transformOut: (v) => {
    return pickBy(v, {
      name: "name",
      base: (v) => {
        return {}
      },
      config: (v) => {
        return pickBy(v, {
          bgImg: "bgImg",
          storeStyle: "storeStyle",
          deliveryStyle: "deliveryStyle",
          textStyle: 'textStyle',
          deliveryInfo: "deliveryInfo",
          width: 'width',
          bottom: 'bottom',
          purpose: 'purpose',
          linkActivity: 'linkActivity'
          // style: "style"
        });
      }
    });
  },
};
