<template>
  <div :class="{ 'wgt-suspension': true }" :style="suspensionStyle">
    <!-- <el-image v-if="value.data.imgUrl" :src="value.data.imgUrl" fit="cover" class="wgt-suspension-img"
      @load='handleLode' :style="`height: ${imgOffset.height}px; width: ${imgOffset.width}px`" /> -->
      <CompHotimg :value="value"/>
  </div>
</template>

<script>
import config from './config'
import CompHotimg from '../../comps/comp_hotImg';
export default {
  name: 'Suspension',
  wgtName: '悬浮层',
  wgtDesc: '',
  wgtIcon: 'wgt-suspension',
  wgtType: 1,
  wgtTem: 1,//0代表微信和抖音小程序都可以配置,1代表只能配置微信
  config: config,
  props: {
    value: [Object, Array]
  },
  data() {
    return {
      imgOffset: {
        width: 0,
        height: 0
      }
    }
  },
  components: {
    CompHotimg
  },
  methods: {
    handleLode(e) {
      const { naturalWidth, naturalHeight } = e.target
      this.imgOffset = {
        width: naturalWidth,
        height: naturalHeight
      }
    }
  },
  computed: {
    suspensionStyle() {
      const { backgroundColor, backgroundImage, position, distance, margin, padding } = this.value
      let style = {
        backgroundColor,
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        margin: `0px ${margin.paddedr}px 0px ${margin.paddedl}px`,
        width: `${375 - margin.paddedl - margin.paddedr}px`,
        padding: `${padding.paddedt}px ${padding.paddedr}px ${padding.paddedb}px ${padding.paddedl}px`,
      }
      if (position === 'top') {
        return {
          ...style,
          top: `${distance}px`,
        }
      } else {
        return {
          ...style,
          bottom: `${distance}px`,
        }
      }
    }
  }

}
</script>

<style lang="scss" scoped>
.wgt-suspension {
  box-sizing: border-box;
  overflow: hidden;
  min-height: 20px;

  &-img {}
}
</style>