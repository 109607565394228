import { pickBy } from '@/utils'
import CompHotSetting from "../../comps/comp_hotSetting";
import CompsStyle from "../../comps/comps-style.vue";

export default {
  name: 'suspension',
  setting: [{
    label: "背景色",
    key: "backgroundColor",
    component: "color",
    value: "#fff",
  },
  {
    label: "背景图",
    key: "backgroundImage",
    component: function (h, { key }) {
      return <SpImagePicker v-model={this.value[key]} size="small" />;
    },
    value: "",
  },
  {
    label: "位置",
    key: "position",
    component: "radiobutton",
    options: [
      { name: "页面顶部", label: "top" },
      { name: "页面底部 ", label: "bottom" },
    ],
    value: "top",
  },
  {
    label: "距离屏幕顶部",
    key: "distance",
    component: "number",
    position: "right",
    size: "mini",
    append: "px",
    min: 0,
    value: 0,
    isShow: function () {
      return this.value.position == 'top'
    }
  },
  {
    label: "距离屏幕底部",
    key: "distance",
    component: "number",
    position: "right",
    size: "mini",
    append: "px",
    min: 0,
    value: 0,
    isShow: function () {
      return this.value.position == 'bottom'
    }
  },
  {
    label: "外边距",
    key: "margin",
    component: function (h, { key }) {
      return (
        <CompsStyle
          showTop={false}
          showBottom={false}
          showBgsetting={false}
          v-model={this.value[key]}
          uuid={this.value.uuid}
          showLabel={false}
        />
      );
    },
    value: {
      paddedr: 0,
      paddedl: 0,
    },
  },
  {
    label: "内边距",
    key: "padding",
    component: function (h, { key }) {
      return (
        <CompsStyle
          showBgsetting={false}
          v-model={this.value[key]}
          uuid={this.value.uuid}
          showLabel={false}
        />
      );
    },
    value: {
      paddedt: 0,
      paddedb: 0,
      paddedl: 0,
      paddedr: 0,
    },
  }, {
    label: "内容设置",
    key: "data",
    component: function (h, { key }) {
      return (
        <CompHotSetting v-model={this.value[key]} uuid={this.value.uuid} />
      );
    },
    value: { imgUrl: "", data: [] },
  },
  ],
  transformIn: async (v) => {
    const { name, base, config, data } = v;
    return {
      name,
      ...base,
      ...config,
      data,
    }
  },
  transformOut: (v) => {
    return pickBy(v, {
      name: 'name',
      base: () => {
        return {}
      },
      config: (v) => {
        return pickBy(v, {
          backgroundColor: 'backgroundColor',
          backgroundImage: 'backgroundImage',
          position: 'position',
          distance: 'distance',
          margin: 'margin',
          padding: 'padding',
        });
      },
      data: "data",
    })
  }
}
