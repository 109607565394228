// 店铺装修
import {
  WgtSlider,
  WgtNewSlider,
  WgtHotZone,
  WgtHoriZontal,
  WgtblokScroll,
  WgtFilm,
  WgtAbtest,
  WgtAroundService,
  WgtStickyLift,
  WgtTabsCut,
  WgtOldGoods,
  WgtOldEvaluate,
  WgtWallPaper,
  WgtAroundStore,
  WgtAroundStoreOne,
  WgtProductShelves,
  WgtContentPart,
  WgtCustomWgt,
  WgtImageText,
  WgtMemberInfo,
  WgtSuspension,
  WgtUserContainer
} from '../wgts/index'

export const initiWidgets = []

export const widgets = {
  WgtSlider,
  WgtNewSlider,
  WgtHotZone,
  WgtHoriZontal,
  WgtblokScroll,
  WgtFilm,
  WgtAbtest,
  WgtAroundService,
  WgtStickyLift,
  WgtTabsCut,
  WgtOldGoods,
  WgtOldEvaluate,
  WgtWallPaper,
  WgtAroundStore,
  WgtAroundStoreOne,
  WgtProductShelves,
  WgtContentPart,
  WgtCustomWgt,
  WgtImageText,
  WgtMemberInfo,
  WgtSuspension,
  WgtUserContainer
}


export default {
  widgets,
  initiWidgets
}
