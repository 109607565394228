// 抖音首页模板
import {
  WgtFilm,
  WgtAroundService,
  WgtHotZone,
  WgtSlider,
  WgtblokScroll,
  WgtAroundStore,
  WgtAroundStoreOne,
} from '../wgts/index'

export const initiWidgets = []

export const widgets = {
  WgtFilm,
  WgtAroundService,
  WgtHotZone,
  WgtSlider,
  WgtblokScroll,
  WgtAroundStore,
  WgtAroundStoreOne,
}


export default {
  widgets,
  initiWidgets
}
