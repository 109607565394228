<template>
  <div :class="{
    'wgt-member-info': true,
  }" :style="memberInfoStyle">
    <div class="wgt-bd" :style="contentStyle">
      <el-image class="avatar" src="https://q2.itc.cn/q_70/images03/20240904/14a2606879394c36a4d1cde4e98bbe1d.png" />
      <div class="info">
        <div class="name">张三</div>
        <div class="level">
          <span class="el-icon-location icon"></span>
          <span class="text">在你身边+</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from './config'
export default {
  name: 'MemberInfo',
  wgtName: '会员信息',
  wgtDesc: '',
  wgtIcon: 'wgt-member-info',
  wgtType: 3,
  wgtTem: 1,//0代表微信和抖音小程序都可以配置,1代表只能配置微信
  config: config,
  props: {
    value: [Object, Array]
  },
  data() {
    return {}
  },
  created() { },
  methods: {},
  computed: {
    memberInfoStyle() {
      const { backgroundImg } = this.value
      return {
        backgroundImage: `url(${backgroundImg})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top center',
      }
    },
    contentStyle() {
      const { padded: { paddedt, paddedb, paddedl, paddedr } } = this.value
      return {
        paddingTop: `${paddedt}px`,
        paddingBottom: `${paddedb}px`,
        paddingLeft: `${paddedl}px`,
        paddingRight: `${paddedr}px`,
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.wgt-member-info {
  box-sizing: border-box;
  width: 375px;
  overflow: hidden;

  .wgt-bd {
    display: flex;
    align-items: center;
    box-sizing: border-box;

    .avatar {
      width: 55px;
      height: 55px;
      border-radius: 100%;
    }

    .info {
      margin-left: 10px;
      display: flex;
      align-items: center;

      .name {
        font-size: 16px;
        font-weight: bold;
        color: #000;
      }

      .level {
        margin-left: 15px;
        font-size: 10px;
        color: #fff;
        background-color: #687af4;
        border-radius: 15px;
        padding: 0px 3px;
        display: flex;
        align-items: center;
        position: relative;

        .icon {
          position: absolute;
          width: 20px;
          height: 20px;
          border-radius: 100%;
          background-color: #687af4;
          display: flex;
          align-items: center;
          justify-content: center;
          left: -10px;
        }

        .text {
          padding-left: 7px;
        }
      }
    }
  }
}
</style>
