import { pickBy } from '@/utils'
import CompsStyle from "../../comps/comps-style.vue";

export default {
  name: 'memberInfo',
  setting: [
    {
      label: "内边距",
      key: "padded",
      component: function (h, { key }) {
        return (
          <CompsStyle
            showBgsetting={false}
            v-model={this.value[key]}
            uuid={this.value.uuid}
            showLabel={false}
          />
        );
      },
      value: {
        paddedt: 10,
        paddedb: 10,
        paddedl: 10,
        paddedr: 10,
      },
    }, {
      label: '背景图片',
      key: 'backgroundImg',
      component: function (h, { key }) {
        return <SpImagePicker v-model={this.value[key]} size='small' />
      },
      value: '',
    },
  ],
  transformIn: (v) => {
    const { name, base = {}, config } = v
    return {
      name,
      ...base,
      ...config
    }
  },
  transformOut: (v) => {
    return pickBy(v, {
      name: 'name',
      base: () => {
        return {};
      },
      config: (v) => {
        return pickBy(v, {
          backgroundImg: "backgroundImg",
          padded: "padded"
        });
      },
    })
  }
}
