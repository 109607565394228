<template>
  <div class="wgt-bd" v-if="value.data.imgUrl" :class="{'spaced': value.spaced}">
    <el-image :src="value.data.imgUrl" />
    <div class="privacy-policy" v-for="(item, index) in privacy" :key="index" :style="`bottom:${item.bottom || 0}px`">
      <div class="privacy-policy__text">
        <span class="privacy-policy__circle"></span>
        <span>我已阅读并接受“Apple 授权专营店商城“的</span>
        <span class="privacy-policy__link">隐私条款</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    privacy() {
      return this.value.data.data.filter(item => item.id === 'register' || item.linkPage === 'ordertogift')
    },
  }

}
</script>

<style lang="scss" scoped>
.wgt-bd {
  position: relative;
  overflow: hidden;

  &.spaced {
    padding: 0 10px;
  }

  .el-image {
    display: block;
  }

  .privacy-policy {
    position: absolute;
    font-size: 10px;
    color: #c6c6c6;
    width: 100%;

    &__circle {
      height: 10px;
      width: 10px;
      min-width: 10px;
      border: 1px solid #c6c6c6;
      border-radius: 100%;
      margin-right: 5px;
    }

    &__text {
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 10px;
      overflow: hidden;

    }

    &__link {
      color: #242424;
      padding-left: 5px;
    }
  }
}
</style>