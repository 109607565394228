import { isArray } from '@/utils'
import { getPagesContentList } from '@/api/template'
import { gWgts } from '../scene'

export const getWgts = (scene) => {
  const { widgets: wgts, initiWidgets = [] } = gWgts[scene]
  let widgets = [...initiWidgets]
  Object.keys(wgts).forEach((index) => {
    widgets.push(wgts[index])
  })
  return widgets
}

export const transformInTem = async (data, widgets) => {
  try {
    const ids = data?.map(item => item?.data?.pages_template_id) || [];
    if(ids.length === 0) return []
    const res = await getPagesContentList({
      pages_template_ids: ids
    })
    const _data = res?.data?.data || []
    console.log('%c_data: ','color: #c75c00; background: #8ccf17; font-size: 22px;',_data);
    const mergedData = data.map(item => {
      const { data, ...rest } = item
      console.log('%c_data[data?.pages_template_id].list: ','color: #c75c00; background: #8ccf17; font-size: 22px;',_data[data?.pages_template_id].list);
      return {
        ...rest,
        cusTom: {
          pages_template_id: data?.pages_template_id,
          template_title: data?.template_title,
          template: transformOutDate(_data[data?.pages_template_id].list, widgets)
        }
      }
    });
    return mergedData
  } catch (error) {
  }
}

export const transformSelectTem = async (data, widgets) => {
  try {
    if (isArray(data)) {
      const ids = data?.map(item => item?.pages_template_id) || [];
      if(ids.length === 0) return []
      const res = await getPagesContentList({
        pages_template_ids: ids
      })
      const _data = res?.data?.data || []
      const mergedData = data?.map(item => {
        const { pages_template_id, template_title, } = item
        return {
          pages_template_id: pages_template_id,
          template_title: template_title,
          template: transformOutDate(_data[pages_template_id].list, widgets)
        }
      });
      return mergedData
    } else {
      const { pages_template_id, template_title } = data
      const res = await getPagesContentList({
        pages_template_ids: [pages_template_id]
      })
      const _data = res?.data?.data
      return transformOutDate(_data[pages_template_id].list, widgets)
    }
  } catch (error) {
  }

}

const transformOutDate = (list, widgets) => {
  const transformedList = [];
  if (!list) return transformedList;
  for (const item of list) {
      const widget = widgets.find(wgt => wgt.name.toLowerCase() === item.name.toLowerCase());
      const { transformIn } = widget?.config || {};
      if (transformIn) {
          const resultOrPromise = transformIn(item.params, widgets);
          if (resultOrPromise instanceof Promise) {
              resultOrPromise.then(result => {
                  transformedList.push(result);
              });
          } else {
              transformedList.push(resultOrPromise);
          }
      } else {
          transformedList.push(null);
      }
  }
  return transformedList;
};