<template>
  <div class="attr-channel">
    <el-select v-model="localValue" multiple placeholder="请选择">
      <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
      </el-option>
    </el-select>

  </div>
</template>

<script>
import api from '@/api'
import { cloneDeep } from 'lodash'
export default {
  props: {
    value: {
      type: Array,
      default: () => []
    },
    uuid: {
      type: String,
      default: ""
    }

  },
  data() {
    return {
      options: [],
      localValue: []
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    async init() {
      const { data } = await api.member.getMemberChannelList({ channel_name: name, channel_id: '' })
      let list = data?.data?.list || []
      this.options = list.map(item => ({ value: item.channel_id, label: item.channel_name }))
    }
  },
  watch: {
    localValue: {
      handler(val) {
        this.$emit("input", val)
      },
      deep: true
    },
    uuid: {
      handler(val) {
        this.localValue = cloneDeep(this.value)
      },
      immediate: true
    }
  },

}
</script>

<style lang="scss" scoped>
.el-tag+.el-tag {
  margin-left: 6px;
}
</style>